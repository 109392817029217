
<template>
  <!-- IMAGE BACKGROUND -->

  <transition-group name="fade" tag="div" class="backgroundImage" appear>
    <div
      v-for="i in [currentIndex]"
      :key="i"
      class="bg"
      :style="{ backgroundImage: 'url(' + currentImg + ')' }"
    ></div>
  </transition-group>

  <!-- ////// IMAGE BACKGROUND -->

  <div class="frame" @click="showMenu" v-if="!showMenuVariable">
    <!-- HOME BUTTON -->

    <div id="homeButton" class="opacite">
      <h1>Camille Bellot</h1>
      <transition-group name="fade" tag="div" appear>
        <h2 v-for="greeting in [greetings]" :key="greeting">
          - {{ currentGreet }} -
        </h2>
      </transition-group>
    </div>

    <!-- ///// HOME BUTTON -->
  </div>

  <!-- GLOBAL MENU -->
  <transition name="fadeFast">
    <globalMenu v-if="showMenuVariable"></globalMenu>
  </transition>

  <!-- /////GLOBAL MENU -->

  <!-- Mobile Popup -->
  <transition name="fadeFast">
    <div class="mobileInfo" v-if="isMobile">
      <button id="close" @click="closeMobilePopup"></button>
      Pour un visualisation optimale des images, pensez à déverrouillez
      «l’orientation portrait» ou à amorcer la «rotation automatique» de votre
      téléphone
    </div>
  </transition>

  <!-- /////Mobile Popup -->
</template>

<script>
import globalMenu from "../components/globalMenu.vue";
import projets from "../projets.json";

export default {
  name: "Home",
  components: { globalMenu },
  data() {
    return {
      bgUrls: [
        "images/home/1.jpg",
        "images/home/2.jpg",
        "images/home/3.jpg",
        "images/home/4.jpg",
      ],
      currentIndex: 0,
      currentGreetIndex: 0,
      greetings: ["Bienvenue", "Welcome"],
      showMenuVariable: false,
      projects: undefined,
      isMobile: true,
    };
  },
  methods: {
    // SlideSHow //////////////////////////////
    startSlide() {
      this.timer = setInterval(this.next, 4000);
    },
    next() {
      this.currentIndex += 1;
    },
    prev() {
      this.currentIndex -= 1;
    },

    // Home Button //////////////////////////////
    showMenu() {
      this.showMenuVariable = true;
    },
    nextGreeting() {
      this.currentGreetIndex += 1;
    },
    startGreeting() {
      this.timerG = setInterval(this.nextGreeting, 3000);
    },

    // preload SlideShow Images //////////////////////////////
    preload(images) {
      for (var i = 0; i < images.length; i++) {
        var img = new Image();
        img.src = images[i];
      }
    },
    closeMobilePopup(){
      this.isMobile = false;
    }
  },
  mounted() {
    this.preload(this.bgUrls);
    this.startSlide();
    this.startGreeting();
    this.projects = projets;
  },
  computed: {
    currentImg: function () {
      return this.bgUrls[Math.abs(this.currentIndex) % this.bgUrls.length];
    },
    currentGreet() {
      return this.greetings[
        Math.abs(this.currentGreetIndex) % this.greetings.length
      ];
    },
  },
};
</script>

<style>
.bg,
.frame {
  position: absolute;
}

.bg {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.frame {
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
}
#homeButton,
.frame {
  mix-blend-mode: screen;
}
#homeButton {
  width: auto;
  background: white;
  padding: 20px 50px;
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  border-radius: 3px;
}
#homeButton h1 {
  margin: 0;
  font-size: 3em;
}
#homeButton h2 {
  margin: 15px 0 0 0;
  text-align: center;
  font-size: 2em;
}

/* /////////////// transitions /////////////////// */
.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: opacity 3s;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 3s;
}

.fadeFast-enter-from {
  opacity: 0;
}

.fadeFast-enter-to {
  opacity: 1;
}

.fadeFast-enter-active {
  transition: opacity 0.2s;
}

.fadeFast-leave-from {
  opacity: 1;
}

.fadeFast-leave-to {
  opacity: 0;
}

.fadeFast-leave-active {
  transition: opacity 0.2;
}

.mobileInfo {
  display: none;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  #homeButton {
    padding: 20px;
  }
  #homeButton h1 {
    margin: 0;
    font-size: 1.8em;
  }

  #homeButton h2 {
    margin-top: 7px;
    font-size: 1.5em;
  }

  .mobileInfo {
    display:block;
    top: 50%;
    position: absolute;
    background: white;
    width: 300px;
    padding: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.1em;
  }
}
</style>
