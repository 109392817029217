<template>
<div class="container">
  <div id="nav" v-if="!showGlobalMenuActive">
    <Menu @showGlobalMenu="showGlobal" @showApropos="showContact"></Menu>
  </div>
  <transition name="fade" tag="div" appear>
    <div v-if="showAprop">
      <div
        class="bg"
        style="background-image: url('../images/contact.jpg')"
      ></div>

      <div class="content opacite" v-if="!showGlobalMenuActive">
        <div class="contactMenu">
          <div id="démarche" @click="showText">
            <span class="boutons opacite" :class="{ active: démarche.active }"
              >Démarche</span
            >
          </div>
          <div id="expos" @click="showText">
            <span class="boutons opacite" :class="{ active: expos.active }"
              >Expos</span
            >
          </div>
          <div id="contact" @click="showText">
            <span class="boutons opacite" :class="{ active: contact.active }"
              >Contact</span
            >
          </div>
        </div>
        <transition name="fadeLeft" tag="div" class="apropos" mode="out-in">
          <div
            v-if="text === 'démarche'"
            v-html="démarche.texte"
            key="démarche"
          ></div>
          <div
            v-else-if="text === 'expos'"
            v-html="expos.texte"
            key="expos"
          ></div>
          <div
            v-else-if="text === 'contact'"
            v-html="contact.texte"
            key="contact"
          ></div>
        </transition>
      </div>
    </div>
  </transition>
  <transition name="fadeGlobalMenu" appear>
    <globalMenu v-if="showGlobalMenuActive" @showP="showProject"></globalMenu>
  </transition>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import globalMenu from "../components/globalMenu.vue";

export default {
  components: {
    Menu: Menu,
    globalMenu: globalMenu,
  },
  data() {
    return {
      démarche: {
        texte:
          '<div class="demarche"><p>Au commencement du travail de Camille Bellot est le matériau. Ces matières premières qui ont servi à l’acheminement, la construction, la consommation. L’artiste sait cette ressource – générée par l’homme – intarissable. Ce flot engendre un besoin de les exploiter, de ne rien laisser de côté.</p><p>Elle les amasse : palettes, armoires, pneus ; la couleur ou l’aspect lui inspirant différents projets. Elle les achève ensuite, les brise à la hache, à la masse ; les réduit à l’état de fragments, tous différents, tous uniques. Le fractionnement agit comme un processus d’appropriation de cette matière première.</p><p> Elle reprend alors les morceaux un-à-un, les choisissant et les assemblant pour former une surface, un volume. L’acharnement indispensable à l’extraction de cette matière se lit dans la forme acérée des fragments assemblés : tranchants, pointus, coupants, les éclats accumulés deviennent majestueux. Ils se réincarnent alors en poils de renard, écailles de dragon, os de dinosaure...</p><p>Comme ils la traversent avec obsession, elle fait renaître les animaux d’un bestiaire fantasmagorique inspiré d’enluminures médiévales ou de récits mythologiques. Sous le spectre des débris urbains, son bestiaire acquiert une sauvagerie qui semble vouloir revenir aux origines même de l’animalité. La violence humaine, contenue dans les morceaux acérés transparaît dans la sauvagerie des animaux qu’elle crée. Le matériau même duquel ils sont issus leur confère un aspect brut, parfois mutant.</p><p>La faune imaginée par Camille Bellot introduit le fantastique dans la réalité, redonne une place au rêve. Féroce et carnassière, elle convoque une sensation primaire – celle de  – où se mêlent méfiance et attirance, où le réel n’a pas de logique et où l’animal est plus puissant que l’homme.</p></div>',
        active: true,
      },
      expos: {
        texte:
          '<div class="expos"><div class="annee">2021</div><ul>  <li><i>UNBOXING,</i> dans le cadre de l’exposition <strong>«Échappé(es)»</strong> (Saumur)</li>  <li><i>LE MESSAGER,</i> biennale d’art contemporain <strong>«Sélest’art»</strong> (Sélestat)</li></ul><div class="annee">2019</div><ul>  <li><i>NIDHÖGG,</i> biennale d’art contemporain <strong>«La forêt Monumentale»</strong> (Rouen)</li>  <li><i>LÉVIATHAN,</i> Festival <strong>«Horizons, Arts-Nature en sancy»</strong> (La Bourboule)</li>  <li><i>L’ÂNE ROI,</i> <strong>Carreau du Temple,</strong> (Paris)</li></ul><div class="annee">2018</div><ul>  <li><i>LES AMOURS DU COLLÈGE LAVALLEY,</i> <strong>collège George Lavalley</strong> (Saint Lô)</li>  <li><i>ARTHROPOLIS,</i> <strong>lycée agricole Gilbert Martin</strong> (Le Neubourg)</li></ul><div class="annee">2017</div><ul>  <li><i>ITADAKIMASU,</i> Salon Révélation, <strong>Grand Palais </strong>(Paris)</li>  <li><i>ÉCLAT,</i> <strong>Centre Jeanne-Hachette</strong> (Ivry-sur-Seine)</li></ul><div class="annee">2016</div><ul>  <li><i>L’HEURE DU REPAS,</i> <strong>Galerie Drouot</strong> (Paris)</li></ul><div class="annee">2015</div><ul>  <li><i>ENVERS,</i> (Saint-Pierre-lès-Nemours)</li>  <li><i>NAMO,</i> <strong>Le Monte-En-l’Air</strong>(Paris)</li></ul><div class="annee">2014</div><ul>  <li><i>MOUVEMENT,</i> <strong>Carreau du Temple,</strong> (Paris)</li>  <li><i>AFTERWORKS,</i> <strong>La galerie des Ateliers de Paris</strong> (Paris)</li></ul><div class="annee">2012</div><ul>  <li><i>CHAMPION DU MONSTRE,</i> <strong>DESIGNER’S DAYS, galerie OFR </strong>(Paris)</li>  <li><i>PIETA,</i> Maison de l’Architecture (Paris)</li></ul></div>',
        active: false,
      },
      contact: {
        texte:
          '<div class="contact"><img src="../images/camille-bellot.jpg" alt="Camille Bellot"><h1>Camille Bellot</h1><p>Née en 1988.<br/>Travaille en collaboration avec François Pottier, architecte D.E.</p><p></p><p>Mail : <a href="mailto:camille.bellotbellot@gmail.com" > camille.bellotbellot@gmail.com</a></p><p>Instagram : <a href="https://www.instagram.com/camille.bellotbellot/" target="_blank">camille.bellotbellot</a></p></div>',
        active: false,
      },
      text: "",
      showAprop: true,
      showGlobalMenuActive: false,
      showMenu: true,
    };
  },
  methods: {
    showText(e) {
      this.text = e.target.parentNode.getAttribute("id");
      switch (this.text) {
        case "démarche":
          this.démarche.active = true;
          this.expos.active = false;
          this.contact.active = false;
          break;
        case "expos":
          this.expos.active = true;
          this.démarche.active = false;
          this.contact.active = false;
          break;
        case "contact":
          this.contact.active = true;
          this.expos.active = false;
          this.démarche.active = false;
          break;
      }
    },
    showContact(e) {
      this.showAprop = true;
      this.text = e.target.text;
    },
    showGlobal() {
      this.showGlobalMenuActive = true;
    },
  },
  computed() {
    this.text = this.$route.params.menu;
  },
  mounted() {
    this.text = this.$route.params.menu;
  },
};
</script>

<style>
.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.content a {
  text-decoration: none;
  color: #80c3a8;
  font-weight: 600;
}

.content a:hover {
  color: #436995;
  opacity: 0.8;
}

.content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 562px;
  height: 80vh;
  background: white;
  padding: 50px 30px;
  border-radius: 3px;
}
.apropos {
  height: 100%;
  overflow: auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.apropos div {
  max-height: 100%;
}

.contactMenu {
  position: absolute;
  right: 0;
  transform: translateX(100%);
  top: 120px;
}

.contactMenu > div {
  display: block;
  margin: 7px 0;
  font-size: 1.5em;
  font-weight: 600;
}

.contactMenu div span {
  display: inline-block;
  background: white;
  padding: 5px 8px;
  cursor: pointer;
  color: #80c3a8;
  border-radius: 0 3px 3px 0;
  text-transform: uppercase;
}

.contactMenu div span:hover {
  -webkit-transition: padding-right 0.1s ease-in-out;
  -moz-transition: padding-right 0.1s ease-in-out;
  -o-transition: padding-right 0.1s ease-in-out;
  transition: padding-right 0.1s ease-in-out;

  padding-right: 15px;
  color: #436995;
}

.contactMenu div span.active {
  color: #436995;
}
.content p {
  font-size: 1.1em;
}

.expos {
  display: grid;
  grid-template-columns: 40px 1fr;
}

.expos .annee {
  font-weight: 600;
}

.expos ul {
  padding: 0;
  margin: 0 0 16px 0;
}

.expos ul li {
  list-style: none;
  margin-bottom: 3px;
}

.apropos img {
  width: 100%;
}

.contact h1 {
  font-weight: 300;
}
/* Animation */

.fadeLeft-enter-from {
  opacity: 0;
  transform: translateX(80px);
}
.fadeLeft-enter-to {
  opacity: 1;
}
.fadeLeft-enter-active {
  transition: all 0.3s;
}

.fadeLeft-leave-from {
  opacity: 1;
}
.fadeLeft-leave-to {
  opacity: 0;
  transform: translateX(-80px);
}
.fadeLeft-leave-active {
  transition: all 0.3s;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .content {
    width: 300px;
    height: 80%;
    padding: 10px 30px;
  }

  .contactMenu {
    top: 12px;
  }

  .contactMenu > div {
    font-size: 1.2em;
  }
}
</style>