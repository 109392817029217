<template>
  <div class="menu">
    <div class="categories">
      <img @click="showGlobalMenu" src="/img/home.svg" alt="home" />
    </div>
    <div class="categories">
      <img src="/img/installations.png" alt="installations" />
      <div class="subMenu">
        <h3>Installations</h3>
        <ul>
          <li v-for="installation in menus[0].menus" :key="installation.id">
            <router-link
              :to="{
                name: 'Installations',
                params: { id: installation.id },
              }"
              @click="showProject"
              :projectId="installation.id"
            >
              {{ installation.menu }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="categories">
      <img src="/img/sculptures.png" alt="sculptures" />
      <div class="subMenu">
        <h3>Sculptures</h3>
        <ul>
          <li v-for="sculpture in menus[1].menus" :key="sculpture.id">
            <router-link
              :to="{ name: 'Sculptures', params: { id: sculpture.id } }"
              @click="showProject"
              :projectId="sculpture.id"
              >{{ sculpture.menu }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="categories">
      <img src="/img/residences.png" alt="residences" />
      <div class="subMenu">
        <h3>Résidences</h3>
        <ul>
          <li v-for="residence in menus[2].menus" :key="residence.id">
            <router-link
              :to="{ name: 'Residences', params: { id: residence.id } }"
              @click="showProject"
              :projectId="residence.id"
              >{{ residence.menu }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="categories">
      <img src="/img/vitrines.png" alt="vitrines" />
      <div class="subMenu">
        <h3>Vitrines</h3>
        <ul>
          <li v-for="vitrines in menus[3].menus" :key="vitrines.id">
            <router-link
              :to="{ name: 'Vitrines', params: { id: vitrines.id } }"
              @click="showProject"
              :projectId="vitrines.id"
              >{{ vitrines.menu }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="categories">
      <img src="/img/apropos.svg" alt="about" />
      <div class="subMenu">
        <h3>À propos</h3>
        <ul>
          <li v-for="about in contact[0].menus" :key="about.id">
            <router-link
              :to="{ name: 'A-propos', params: { menu: about.menu } }"
              @click="showApropos"
              :projectId="about.id"
              >{{ about.menu }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import jsonMenu from '../menus.json'
export default {
  data() {
    return {
      menus: jsonMenu,
      contact: [
        {
          "category": "à propos",
          "menus": [
            {
              "id": 1,
              "menu": "démarche",
            },
            {
              "id": 2,
              "menu": "expos",
            },
            {
              "id": 3,
              "menu": "contact",
            },
          ],
        },
      ],
    };
  },
  methods: {
    showProject(event) {
      this.$emit("showP", event);
    },
    showApropos(event) {
      this.$emit("showApropos", event);
    },
    showGlobalMenu(event) {
      this.$emit("showGlobalMenu", event);
    },
  },
};
</script>
<style>
#nav {
  position: absolute;
  top: 0;
  padding: 5px;
  z-index: 20;
  text-transform: uppercase;
  opacity: 0.94;
}
#nav a {
  display: block;
  float: none;
  margin-bottom: 2px;
  text-decoration: none;
  color: #000;
}
#nav .subMenu .router-link-active {
  font-weight: 700;
}
#nav img {
  width: 45px;
  float: none;
  display: block;
  margin-bottom: 4px;
  border-radius: 3px;
  cursor: pointer;
}
#nav .menu {
  position: relative;
}
#nav .subMenu {
  width: 140px;
  opacity: 0.94;
  position: absolute;
  left: 50px;
  background: #fff;
  padding: 5px 15px;
  display: none;
  transform: translateY(-49px);
  border-radius: 3px;
}
#nav .categories {
  width: 65px;
}
#nav .categories:hover .subMenu {
  display: block;
}
#nav .subMenu h3 {
  margin: 0 0 2px 0;
}
#nav .subMenu ul {
  margin-top: 8px;
  padding-left: 0;
  list-style: none;
}
#nav .subMenu ul li {
  cursor: pointer;
  margin-bottom: 5px;
}
#nav .subMenu ul li:hover {
  cursor: pointer;
  font-weight: 900;
}

@media screen and (max-width:1024px) {
  #nav{
    scale:0.9;
    top:-10px;
  }

    #nav .categories:nth-child(5) .subMenu {
    transform: translateY(-103%);
}

  #nav .categories:nth-child(6) .subMenu {
    transform: translateY(-103%);
}

}
</style>