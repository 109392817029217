<template>
  <div class="installations">
    <!-- Menu ///////////////////////-->

    <div id="nav" v-if="!showGlobalMenuActive">
      <Menu @showP="showProject" @showGlobalMenu="showGlobal"></Menu>
    </div>

    <!-- // Menu ///////////////////////-->

    <!-- Project ///////////////////////-->

    <transition name="fade-between-projects" @after-leave="afterLeave">
      <div v-if="showMyProject">
        <div v-if="myProject">
          <transition-group name="fade-project-images" tag="div" appear>
            <div
              v-for="i in [currentIndex]"
              :key="i"
              class="bg"
              :style="{ backgroundImage: 'url(' + currentImg + ')' }"
            ></div>
          </transition-group>
          <button class="prev" @click="prev"></button>
          <button class="next" @click="next"></button>
          <div class="texte" :class="{ showTexte: isTextActive }">
            <button @click="showText"><span>+</span></button>
            <div>
              <div>
                <h1 v-html="myProject.expo"></h1>
                <h1 v-html="myProject.titre"></h1>
                <h2>{{ myProject.artistes }}</h2>
                <p class="infos" v-html="myProject.infos"></p>
                <p class="materiaux" v-html="myProject.materiaux"></p>
                <div class="mainText" v-html="myProject.mainText"></div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <p>Loading...</p>
        </div>
      </div>
    </transition>

    <!-- // Project ///////////////////////-->
  </div>

  <!-- Pop up ///////////////////////-->
  <div v-if="showPopup">
    <transition name="fade">
      <div id="popup" v-if="displayPopup">
        <!-- <button id="close" @click="closePopup"></button> -->
        <p>
          Cliquez à droite ou à gauche de l'écran pour naviguer dans les images
        </p>
        <img
          style="width: 80%"
          src="../../public/images/popup.png"
          alt="cliquez à droite ou à gauche de l'écran pour naviguer dans les images"
        />
      </div>
    </transition>
  </div>

  <!-- // Pop up ///////////////////////-->

  <!-- //Global Menu ///////////////////////-->

  <transition name="fadeGlobalMenu" appear>
    <globalMenu v-if="showGlobalMenuActive" @showP="showProject"></globalMenu>
  </transition>

  <!-- //Global Menu ///////////////////////-->
</template>

<script>
import Menu from "../components/Menu.vue";
import projets from "../projets.json";
import globalMenu from "../components/globalMenu.vue";

export default {
  components: {
    Menu: Menu,
    globalMenu: globalMenu,
  },
  data() {
    return {
      id: this.$route.params.id,
      cat: this.$route.name.toLowerCase(),
      isTextActive: false,
      menus: [
        {
          category: "INSTALLATIONS",
          menus: [
            {
              id: 1,
              menu: "LÉVIATHAN",
            },
            {
              id: 2,
              menu: "NIDHÖGG",
            },
            {
              id: 3,
              menu: "UNBOXING",
            },
            {
              id: 4,
              menu: "LE MESSAGER",
            },
            {
              id: 5,
              menu: "UN TRAVAIL DE FOURMIS",
            },
            {
              id: 6,
              menu: "ARACHNÉE",
            },
          ],
        },
        {
          category: "SCULPTURES",
          menus: [
            {
              id: 1,
              menu: "ITADAKIMASU",
            },
            {
              id: 2,
              menu: "T-REX",
            },
            {
              id: 3,
              menu: "L’HEURE DU REPAS",
            },
            {
              id: 4,
              menu: "SAUVAGE",
            },
            {
              id: 5,
              menu: "ENVERS",
            },
          ],
        },
        {
          category: "RÉSIDENCES",
          menus: [
            {
              id: 1,
              menu: "ARTHROPOLIS",
            },
            {
              id: 2,
              menu: "LES AMOURS DU COLLÈGE LAVALLEY",
            },
          ],
        },
        {
          category: "Vitrines",
          menus: [
            {
              id: 1,
              menu: "HERMÈS",
            },
            {
              id: 2,
              menu: "SHANG XIA",
            },
            {
              id: 3,
              menu: "BONPOINT",
            },
            {
              id: 4,
              menu: "DINH VAN",
            },
          ],
        },
      ],
      projects: null,
      myProject: null,
      showMyProject: true,
      currentIndex: 0,
      myImages: [],
      everyFirstImage: [],
      displayPopup: true,
      showPopup: true,
      showGlobalMenuActive: false,
      showMenu: false,
    };
  },
  methods: {
    /////////////// Slide Show
    next: function () {
      this.currentIndex += 1;
      this.updateText();
    },
    prev: function () {
      if (this.currentIndex == 0) {
        this.currentIndex = this.myImages.length;
      }
      this.currentIndex -= 1;
      this.updateText();
    },
    updateText() {
      switch (this.currentImg) {
        case "../images/installations/unboxing/unboxing1.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "<br/>Photographie : <strong>Christophe Gagneux – Pixim communication</strong>",
            ""
          );
          break;
        case "../images/installations/unboxing/unboxing2.jpg":
          if (
            this.myProject.infos.search(
              "<br/>Photographie : <strong>Christophe Gagneux – Pixim communication</strong>"
            ) === -1
          ) {
            this.myProject.infos +=
              "<br/>Photographie : <strong>Christophe Gagneux – Pixim communication</strong>";
          }
          break;
        case "../images/installations/unboxing/unboxing3.jpg":
          if (
            this.myProject.infos.search(
              "<br/>Photographie : <strong>Christophe Gagneux – Pixim communication</strong>"
            ) === -1
          ) {
            this.myProject.infos +=
              "<br/>Photographie : <strong>Christophe Gagneux – Pixim communication</strong>";
          }
          break;
        case "../images/installations/unboxing/unboxing4.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "<br/>Photographie : <strong>Christophe Gagneux – Pixim communication</strong>",
            ""
          );
          break;
        case "../images/sculptures/itadakimasu/itadakimasu2.jpg":
          this.myProject.infos +=
            "<br/>Photographie : <strong>Pierre&Florent</strong>";
          break;
        case "../images/sculptures/itadakimasu/itadakimasu1.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "<br/>Photographie : <strong>Pierre&Florent</strong>",
            ""
          );
          break;
        case "../images/sculptures/sauvage/sauvage2.jpg":
          if (
            this.myProject.infos.search(
              "<br/>Photographie : <strong>Elliot Sibon</strong>"
            ) === -1
          ) {
            this.myProject.infos +=
              "<br/>Photographie : <strong>Elliot Sibon</strong>";
          }
          break;
        case "../images/sculptures/sauvage/sauvage1.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "<br/>Photographie : <strong>Elliot Sibon</strong>",
            ""
          );
          break;
        case "../images/sculptures/sauvage/sauvage3.jpg":
          if (
            this.myProject.infos.search(
              "<br/>Photographie : <strong>Elliot Sibon</strong>"
            ) === -1
          ) {
            this.myProject.infos +=
              "<br/>Photographie : <strong>Elliot Sibon</strong>";
            break;
          }
          break;
        case "../images/residences/lesamoursducollegelavalley/lesamoursducollegelavalley2.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "Benjamin Barda",
            "Rémi Bellot"
          );
          break;
        case "../images/residences/lesamoursducollegelavalley/lesamoursducollegelavalley6.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "Benjamin Barda",
            "Rémi Bellot"
          );
          break;
        case "../images/residences/lesamoursducollegelavalley/lesamoursducollegelavalley1.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "Rémi Bellot",
            "Benjamin Barda"
          );
          break;
        case "../images/residences/lesamoursducollegelavalley/lesamoursducollegelavalley7.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "Rémi Bellot",
            "Benjamin Barda"
          );
          break;
        case "../images/vitrines/hermes/hermes6.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "Alex Jonas",
            "Florent Ormond"
          );
          break;
        case "../images/vitrines/hermes/hermes7.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "Alex Jonas",
            "Florent Ormond"
          );
          break;
        case "../images/vitrines/hermes/hermes5.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "Florent Ormond",
            "Alex Jonas"
          );
          break;
        case "../images/vitrines/hermes/hermes1.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "Florent Ormond",
            "Alex Jonas"
          );
          break;
        case "../images/vitrines/dinhvan/dinhvan1.jpg":
          this.myProject.titre = "<strong>Dinh Van,</strong> Noël 2022"
          this.myProject.materiaux = this.myProject.materiaux
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de Noël de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan2.jpg":
          this.myProject.titre = "<strong>Dinh Van,</strong> Noël 2022"
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de Noël de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan3.jpg":
          this.myProject.titre = this.myProject.titre34
          this.myProject.materiaux = this.myProject.materiaux34
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de Noël de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan4.jpg":
          this.myProject.titre = this.myProject.titre34
          this.myProject.materiaux = this.myProject.materiaux34
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de Noël de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan5.jpg":
          this.myProject.titre = this.myProject.titre56
          this.myProject.materiaux = this.myProject.materiaux56
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de Noël de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan6.jpg":
          this.myProject.titre = this.myProject.titre56
          this.myProject.materiaux = this.myProject.materiaux56
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de Noël de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan7.jpg":
          this.myProject.titre = this.myProject.titre78
          this.myProject.materiaux = this.myProject.materiaux
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de janvier de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan8.jpg":
          this.myProject.titre = this.myProject.titre78
          this.myProject.materiaux = this.myProject.materiaux
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de janvier de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan9.jpg":
          this.myProject.titre = this.myProject.titre910
          this.myProject.materiaux = this.myProject.materiaux34
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de printemps de la marque DINH VAN.</p>"
          break;
        case "../images/vitrines/dinhvan/dinhvan10.jpg":
          this.myProject.titre = this.myProject.titre910
          this.myProject.materiaux = this.myProject.materiaux34
          this.myProject.mainText = "<p>Décor conçu et réalisé pour le réseau international des vitrines de printemps de la marque DINH VAN.</p>"
          break;
        case "../images/installations/surnature/03_empreintes_suspectes/03_empreintes_suspectes1.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>",
            ""
          );
          break;
        case "../images/installations/surnature/03_empreintes_suspectes/03_empreintes_suspectes2.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>",
            ""
          );
          break;
        case "../images/installations/surnature/03_empreintes_suspectes/03_empreintes_suspectes3.jpg" :
          if (
            this.myProject.infos.search(
              "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>"
            ) === -1
          ) {
            this.myProject.infos +=
              "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>";
          }
          break;
        case "../images/installations/surnature/03_empreintes_suspectes/03_empreintes_suspectes4.jpg":
          if (
            this.myProject.infos.search(
              "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>"
            ) === -1
          ) {
            this.myProject.infos +=
              "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>";
          }
          break;
        case "../images/installations/surnature/03_empreintes_suspectes/03_empreintes_suspectes5.jpg":
          if (
            this.myProject.infos.search(
              "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>"
            ) === -1
          ) {
            this.myProject.infos +=
              "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>";
          }
          break;
        case "../images/installations/surnature/05_vanités_carnivores/05_vanités_carnivores4.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>",
            ""
          );
          break;
        case "../images/installations/surnature/05_vanités_carnivores/05_vanités_carnivores5.jpg":
          if (
            this.myProject.infos.search(
              "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>"
            ) === -1
          ) {
            this.myProject.infos +=
              "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>";
          }
          break;
        case "../images/installations/surnature/05_vanités_carnivores/05_vanités_carnivores1.jpg":
          this.myProject.infos = this.myProject.infos.replace(
            "<br/>Photographie : <strong>Bagnoles de l'Orne Tourisme</strong>",
            ""
          );
          break;
        
      }
    },
    ///////////// Text
    showText() {
      this.isTextActive = !this.isTextActive;
    },
    /////////////// Change menu
    showProject(e) {
      this.showPopup = false;
      this.showGlobalMenuActive = false;
      this.currentIndex = 0;
      // On récupère la catégorie
      this.cat = e.target.getAttribute("href").split("/")[1];
      //On récupère l'id du projet
      this.id = e.target.getAttribute("projectid");
      // On récupère le bon objet en fonction des valeurs ci-dessus

      this.showMyProject = !this.showMyProject;
      this.myProject = this.findProject;
      this.myImages = this.myProject.images;
      this.preload(this.myImages);
    },
    afterLeave() {
      this.showMyProject = !this.showMyProject;
    },
    showContact() {
      this.myProject = null;
    },
    preload(images) {
      for (var i = 1; i < images.length; i++) {
        var img = new Image();
        img.src = images[i];
      }
    },
    getEveryFirstImage() {
      for (var j = 0; j < this.projects.length; j++) {
        for (var i = 0; i < this.projects[j].projs.length; i++) {
          this.everyFirstImage.push(this.projects[j].projs[i].images[0]);
        }
      }
      this.preload(this.everyFirstImage);
    },
    closePopup() {
      this.displayPopup = false;
    },
    showGlobal() {
      this.isTextActive = false;
      this.showGlobalMenuActive = true;
    },
  },
  computed: {
    //on retrouve d'abord la bonne catégorie
    findCat() {
      return this.projects.find((project) => project.category == this.cat);
    },
    //On retrouve le projet dans le tableau "findCat" en fonction de l'id
    findProject() {
      return this.findCat.projs.find((ex) => ex.id == this.id);
    },
    currentImg: function () {
      return this.myImages[Math.abs(this.currentIndex) % this.myImages.length];
    },
  },
  mounted() {
    this.projects = projets;
    this.myProject = this.findProject;
    this.myImages = this.myProject.images;
    this.currentImg = this.myImages[0];
    this.preload(this.myImages);
    setTimeout(() => {
      this.closePopup();
    }, 3000);
    setTimeout(() => {
      this.getEveryFirstImage();
    }, 1500);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/a-propos/d%C3%A9marche") {
        vm.showPopup = false;
      } else if (from.path === "/a-propos/expos") {
        vm.showPopup = false;
      } else if (from.path === "/a-propos/contact") {
        vm.showPopup = false;
      }

      next();
    });
  },
};
</script>

<style>
.installations {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.bg {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}

/* /////////////////// Text  ///////////////////////////////////// */

.texte {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #fff;
  width: 36%;
  transition: transform 0.5s;
  transform: translateX(100%);
  opacity: 0.94;
  font-weight: 600;
}

.texte > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 15%;
  overflow: auto;
  max-height: 95vh;
  display: flex;
}
.infos {
  margin-top: 50px;
}

.materiaux {
  margin-bottom: 50px;
}

.infos,
.materiaux {
  font-style: italic;
  font-weight: 300;
  font-size: 0.9em;
}

.infos strong {
  font-weight: 500;
}

.texte h1 {
  font-weight: 200;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.texte h1 strong {
  font-weight: 600;
}
.texte h2 {
  font-size: 1.1em;
  font-weight: 200;
}

.texte .mainText i {
  font-weight: 600;
}
/* /////////////////// Text Button    ///////////////////////////////////// */

.showTexte {
  transform: translateX(0);
  transition: 0.3s ease;
}

.texte button {
  position: absolute;
  border: none;
  overflow: auto;
  background: white;
  top: 250px;
  left: -45px;
  width: 45px;
  height: 45px;
  font-size: 2em;
  font-weight: 700;
  border-radius: 3px 0 0 3px;
}

.texte button span {
  transform: rotate(0deg);
  display: inline-block;
  transition: 0.2s ease;
  max-width: 45px;
}

.texte button span:hover {
  transform: rotate(-90deg);
  transition: 0.2s ease;
}

.showTexte button span {
  transform: rotate(-45deg);
  transition: 0.2s ease;
}

.showTexte button span:hover {
  transform: rotate(45deg);
  transition: 0.2s ease;
}
/* ///// Popup //////////////////////// */

#popup {
  background: white;
  top: 50%;
  left: 50%;
  width: 450px;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 20px 30px;
  border-radius: 3px;
  text-align: center;
}

#close {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  border: none;
  padding: 0;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: transparent;
  color: #000;
  font: inherit;
  text-indent: 100%;
  cursor: pointer;
  transition: 0.2s ease;
}

#close:hover {
  transform: rotate(-90deg);
  transition: 0.2s ease;
}

#close:before,
#close:after {
  position: absolute;
  top: 15%;
  left: calc(50% - 0.0625em);
  width: 0.125em;
  height: 70%;
  border-radius: 0.125em;
  transform: rotate(45deg);
  background: currentcolor;
  content: "";
}

#close:after {
  transform: rotate(-45deg);
}

/* ///// Navigation images //////////////////////// */

button.prev {
  position: absolute;
  background: transparent;
  bottom: 0;
  width: 50%;
  height: 100vh;
  border: none;
  cursor: pointer;
  padding:0;
}

button.next {
  position: absolute;
  background: transparent;
  bottom: 0;
  width: 50%;
  height: 100vh;
  border: none;
  transform: translateX(100%);
  cursor: pointer;
  padding:0;
}

/* /////////////// transitions /////////////////// */

.fadeGlobalMenu-enter-from {
  opacity: 0;
}

.fadeGlobalMenu-enter-to {
  opacity: 1;
}

.fadeGlobalMenu-enter-active {
  transition: opacity 0.5s;
}

.fadeGlobalMenu-leave-from {
  opacity: 1;
}

.fadeGlobalMenu-leave-to {
  opacity: 0;
}

.fadeGlobalMenu-leave-active {
  transition: opacity 0.5s;
}

.fade-project-images-enter-from {
  opacity: 0;
}

.fade-project-images-enter-to {
  opacity: 1;
}

.fade-project-images-enter-active {
  transition: opacity 1s;
}

.fade-project-images-leave-from {
  opacity: 1;
}

.fade-project-images-leave-to {
  opacity: 0;
}

.fade-project-images-leave-active {
  transition: opacity 0.5s;
}

.fade-between-projects-enter-from {
  opacity: 0;
}

.fade-between-projects-enter-to {
  opacity: 1;
}

.fade-between-projects-enter-active {
  transition: opacity 0.5s;
}

.fade-between-projects-leave-from {
  opacity: 1;
}

.fade-between-projects-leave-to {
  opacity: 0;
}

.fade-between-projects-leave-active {
  transition: opacity 1;
}

/* Media Queries */

@media screen and (max-width: 1024px) {
  #popup {
    scale: 0.8;
    transform-origin: center;
  }

  .texte {
    width: 50%;
  }

  .texte button {
    top: 25px;
    scale: 0.9;
    transform-origin: right;
  }
}
</style>