import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Project from '../views/Project.vue'
import Contact from '../views/Contact.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/installations/:id',
    name: 'Installations',
    component: Project
  },
  {
    path: '/sculptures/:id',
    name: 'Sculptures',
    component: Project
  },
  {
    path: '/residences/:id',
    name: 'Residences',
    component: Project
  },
  {
    path: '/vitrines/:id',
    name: 'Vitrines',
    component: Project
  },
  {
    path: '/a-propos/:menu',
    name: 'A-propos',
    component: Contact
  },
  {
    //404 Not Found
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
